import store from "src/page/store";
import client from "../client";
import { PaginationRequest, subscriptionCategory, subscriptionStandard } from "src/types/commonTypes";

export interface SubsListRequest extends PaginationRequest {
  subscription_filter: {
    get_parent_subscription?: boolean;
    parent_subscription_id?: string;
    organisation_type?: string;
    is_active?: boolean;
    subscription_ids?: string[];
    standard?: subscriptionStandard;
    subscription_category?: subscriptionCategory;
    level?: string;
    include_preferences?: boolean;
  };
}

export const listSubscription = (data: SubsListRequest) => {
  const { auth } = store.getState();

  return client({
    query: `
    query SearchSubscriptions($input: SearchInput) {
      search_subscriptions(input: $input) {
        total_pages
        total_size
        items {
          organisation_type
          title
          description
          subtitle
          subscription_id
          created_at
          is_active
          standard
          subscription_category
          level
          preferences {
            permission { 
              role_permission_mapping { 
                ${auth?.userInfo?.role}
              }
            } 
            menu { 
              role_menu_mapping { 
                ${auth?.userInfo?.role}
              }
            }
          }
        }
      }
    }
    `,
    variables: {
      input: {
        from: data.from,
        size: data.size,
        filter: {
          subscription_filter: data.subscription_filter
        }
      }
    }
  });
};

export const getSubscription = (id: string) => {
  return client({
    query: `
    query GetSubscription($input: GetSubscriptionInput) {
      get_subscription(input: $input) {
        organisation_type
        title
        description
        subtitle
        subscription_id
        created_at
        is_active
        preferences {
          permission { 
            role_permission_mapping 
            { 
              PARTICIPANT
              SUPPORTER 
              ORGANISATION_FINANCE_MANAGER
              ORGANISATION_OPERATIONS_MANAGER
              ORGANISATION_SUPER_ADMIN
              INTERNAL_ADMIN
            }
          } 
          menu { 
            role_menu_mapping { 
              PARTICIPANT 
              SUPPORTER
              ORGANISATION_FINANCE_MANAGER
              ORGANISATION_OPERATIONS_MANAGER
              ORGANISATION_SUPER_ADMIN
              INTERNAL_ADMIN
            }
          }
        }
      }
    }
    `,
    variables: {
      input: {
        subscription_id: id
      }
    }
  });
};

export type SubsRequest = {
  subscription_id?: string;
  parent_subscription_id?: string;
  organisation_type: string;
  title: string;
  description: string;
  subtitle?: string;
  subscription_category: subscriptionCategory;
  level: number;
};

export const createSubscription = (data: SubsRequest) => {
  return client({
    query: `
    mutation CreateSubscription($input: CreateSubscriptionInput) {
      create_subscription(input: $input) {
        organisation_type
        title
        description
        subtitle
        subscription_id
        created_at
      }
    }`,
    variables: {
      input: data
    }
  });
};

export const setStandardSubscription = (subscriptionId: string) => {
  return client({
    query: `
    mutation SetStandardSubscription($input: SetStandardSubscriptionInput) {
      set_standard_subscription(input: $input) {
        organisation_type
        title
        description
        subtitle
        subscription_id
        created_at
      }
    }`,
    variables: {
      input: {
        subscription_id: subscriptionId
      }
    }
  });
};

export const updateSubscription = (data: SubsRequest) => {
  return client({
    query: `
    mutation UpdateSubscriptions($input: UpdateSubscriptionInput) {
      update_subscription(input: $input) {
        subscription_id
        organisation_type
        title
        description
        subtitle
        created_at
        updated_at
      }
    }`,
    variables: {
      input: data
    }
  });
};

export const toggleSubscription = (subId: string, isActive: boolean) => {
  return client({
    query: `
    mutation ToggleSubscriptionState($input: ToggleSubscriptionStateInput) {
      toggle_subscription_state(input: $input) {
        subscription_id
        title
        created_at
        subtitle
        description
        organisation_type
        updated_at
        is_active
      }
    }`,
    variables: {
      input: {
        subscription_id: subId,
        is_active: isActive
      }
    }
  });
};

export const getSubscriptionOrganisations = (id: string) => {
  return client({
    query: `
    query GetSubscriptionOrganisations($input: GetSubscriptionOrganisationsInput) {
      get_subscription_organisations(input: $input) {
        organisation_id
        name
      }
    }
    `,
    variables: {
      input: {
        subscription_id: id
      }
    }
  });
};

export const getOrganisationSubscriptions = (id: string) => {
  return client({
    query: `
    query GetOrganisationSubscriptions($input: GetOrganisationSubscriptionsInput) {
      get_organisation_subscriptions(input: $input) {
        subscription_id
      }
    }
    `,
    variables: {
      input: {
        organisation_id: id
      }
    }
  });
};

export const connectOrganisationToSubscription = (organisationId: string, subscriptionId: string) => {
  return client({
    query: `
    mutation ConnectOrganisationToSubscription($input: ConnectOrganisationToSubscriptionInput) {
      connect_organisation_to_subscription(input: $input) {
        relationship_id
        organisation_id
        status
      }
    }`,
    variables: {
      input: {
        organisation_id: organisationId,
        subscription_id: subscriptionId
      }
    }
  });
};

export const removeOrganisationFromSubscription = (organisationId: string, subscriptionId: string) => {
  return client({
    query: `
    mutation RemoveOrganisationFromSubscription($input: RemoveOrganisationToSubscriptionInput) {
      remove_organisation_from_subscription(input: $input) {
        relationship_id
        organisation_id
        status
      }
    }`,
    variables: {
      input: {
        organisation_id: organisationId,
        subscription_id: subscriptionId
      }
    }
  });
};

export type saveSubsRequest = {
  subscription_id: string;
  permission: { role_permission_mapping: { [role: string]: string[] } };
  menu: { role_menu_mapping: { [role: string]: string[] } };
};

export const saveSubscriptionPreference = (data: saveSubsRequest) => {
  return client({
    query: `
    mutation SaveSubscriptionPreference($input: SubscriptionPreferencesInput) {
      save_subscription_preferences(input: $input) {
        preference_id
      }
    }`,
    variables: {
      input: data
    }
  });
};
