import { useState } from "react";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/PersonAddAlt1";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modalui from "../UI/modal/Modalui";
import AddUserModal from "../userList/addUserModal/AddUserModal";
import AddWalletModal from "../walletList/addWalletModal/AddWalletModal";
import OrderCardModal from "../orderCardModal/OrderCardModal";
import WalletUserModal from "../connectCard/supporterModal/WalletUserModal";
import OnBoardingSuccess from "src/components/onBoardingComponent/OnBoardingSuccessCard";
import styles from "./welcomeCard.module.scss";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { addUserAction, addWalletAction } from "src/page/onBoarding/onBoardingSlice";
import {
  PARTICIPANT,
  SUPPORTER,
  roleMapping,
  FINANCE_MANAGER,
  CAN_CREATE_GENERAL_WALLET,
  CAN_CREATE_CASH_WALLET
} from "src/components/constant/constant";
import { CreateUserRequest } from "src/network/graphql/userService";
import { AddWalletIn } from "src/components/walletList/addWalletModal/AddWalletModal";
import ParticipantSuccess from "src/assets/images/ParticipantSuccess.png";
import WalletCreated from "src/assets/images/success2.png";
import { addWalletSupporterAction } from "src/page/userDetail/userDetailSlice";
import OnBoardComplete from "src/assets/images/CompleteOnboarding.png";
import { checkPermission, upgradePlan } from "src/utils/utils";

const QuickLinks = () => {
  const dispatch = useAppDispatch();
  const { userDetail, userLoading, walletLoading } = useAppSelector((Rstate) => Rstate.onBoarding);
  const [modalState, setModalState] = useState<{
    type: string | null;
    selectedOption: string;
    open: boolean;
  }>({ type: null, selectedOption: "", open: false });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = (type: string, option: string = "") => {
    setModalState({ type, selectedOption: option, open: true });
    handleCloseMenu();
  };

  const handleModalClose = () => {
    setModalState({ ...modalState, open: false });
  };

  const handleUserAdded = (userData: CreateUserRequest) => {
    dispatch(
      addUserAction(userData, () => {
        handleModalClose();
        handleModalOpen("CreateWallet", modalState.selectedOption);
      })
    );
  };

  const handleWalletAdded = (walletData: AddWalletIn) => {
    const completeWalletData = {
      ...walletData,
      name: walletData.walletName,
      description: walletData.description,
      userId: String(userDetail?.user?.id)
    };

    if (walletData?.walletType === "GENERAL" && !checkPermission(CAN_CREATE_GENERAL_WALLET)) {
      handleModalClose();
      upgradePlan({
        title: "Your current subscription plan does not include access to create a General wallet.",
        subTitle: "Please consider upgrading to unlock this feature."
      });
    } else if (walletData?.walletType === "CASH" && !checkPermission(CAN_CREATE_CASH_WALLET)) {
      handleModalClose();
      upgradePlan({
        title: "Your current subscription plan does not include access to create a Cash wallet.",
        subTitle: "Please consider upgrading to unlock this feature."
      });
    } else {
      dispatch(
        addWalletAction(completeWalletData, () => {
          handleModalClose();
          if (walletData?.walletType === "CASH") {
            handleModalOpen("orderCardSuccess", modalState.selectedOption);
          } else {
            handleModalOpen("walletCreatedSuccess", modalState.selectedOption);
          }
        })
      );
    }
  };

  const handleOrderCardSuccess = () => {
    handleModalClose();
    handleModalOpen("orderCardSuccess", modalState.selectedOption);
  };

  const handleAddSupporter = (email: string, walletId: string, close: boolean, applyToAll: boolean) => {
    if (userDetail?.user?.id) {
      dispatch(
        addWalletSupporterAction(walletId, String(userDetail?.user?.id), applyToAll, () => {
          handleModalClose();
          handleModalOpen("orderCardSuccess", modalState.selectedOption);
        })
      );
    }
  };

  const renderModal = () => {
    const { type, selectedOption, open } = modalState;
    switch (type) {
      case "AddUser":
        return (
          <Modalui open={open} handleClose={handleModalClose} modaluiOver={styles.addUserModal}>
            <AddUserModal
              handleClose={handleModalClose}
              onAddUser={handleUserAdded}
              role={selectedOption}
              secondaryButton={false}
              heading={`Add ${roleMapping[selectedOption]}`}
              buttonText={{ primaryButton: "Next" }}
              userloading={userLoading}
            />
          </Modalui>
        );
      case "CreateWallet":
        return (
          <Modalui open={open} handleClose={handleModalClose} modaluiOver={styles.modaluiOver}>
            <OnBoardingSuccess
              onNext={() =>
                selectedOption === PARTICIPANT
                  ? handleModalOpen("AddWallet", selectedOption)
                  : handleModalOpen("WalletUserModal", selectedOption)
              }
              onBack={() => handleModalClose()}
              heading={
                selectedOption === PARTICIPANT
                  ? "Your Participant needs a Wallet!"
                  : "We need to connect them to a participant wallet"
              }
              subHeading={
                selectedOption === PARTICIPANT
                  ? "Give your participant their first wallet so that they can add their money there. The wallet allows you to deposit, withdraw and spend while keeping all the evidence."
                  : "Give this person access to a wallet of a parent so that they can see what is going on in there and support the participant with their money handling."
              }
              buttonText={{ primaryButton: selectedOption === PARTICIPANT ? "Create a Wallet" : "Connect Wallets" }}
              image={ParticipantSuccess}
            />
          </Modalui>
        );
      case "AddWallet":
        return (
          <Modalui open={open} handleClose={handleModalClose} modaluiOver={styles.walletmodaluiOver}>
            <AddWalletModal
              handleClose={handleModalClose}
              onAddWallet={handleWalletAdded}
              secondaryButton={false}
              buttonText={{ primaryButton: "Next" }}
              isWalletListEmpty={true}
              userId={String(userDetail?.user?.id)}
              walletloading={walletLoading}
            />
          </Modalui>
        );
      case "OrderCard":
        return (
          <Modalui open={open} handleClose={handleModalClose} modaluiOver={styles.cardmodaluiOver}>
            <OrderCardModal
              handleClose={handleModalClose}
              onSuccess={handleOrderCardSuccess}
              userDetail={userDetail}
              hideUserInput={true}
              secondaryButton={false}
              buttonText={{ primaryButton: "Submit" }}
            />
          </Modalui>
        );
      case "WalletUserModal":
        return (
          <Modalui open={open} handleClose={handleModalClose} modaluiOver={styles.walletmodaluiOver}>
            <WalletUserModal
              handleClose={handleModalClose}
              participantName={userDetail?.user?.firstName || ""}
              onAddSupporter={handleAddSupporter}
              buttonText={{ primaryButton: "Next" }}
              secondaryButton={false}
            />
          </Modalui>
        );
      case "walletCreatedSuccess":
        return (
          <Modalui open={open} handleClose={handleModalClose} modaluiOver={styles.modaluiOver}>
            <OnBoardingSuccess
              onNext={() => handleModalOpen("OrderCard", selectedOption)}
              onBack={() => handleModalClose()}
              heading="You’re almost done!"
              subHeading="You need cards so that you can pay! Order a card for your participant that either they or the support workers will use."
              buttonText={{ primaryButton: "Order your first card" }}
              image={WalletCreated}
            />
          </Modalui>
        );
      case "orderCardSuccess":
        return (
          <Modalui open={open} handleClose={handleModalClose} modaluiOver={styles.modaluiOver}>
            <OnBoardingSuccess
              onNext={() => {
                handleModalClose();
              }}
              onBack={() => handleModalClose()}
              heading="Your Onboarding is Complete!"
              subHeading={`You have successfully onboarded a new ${roleMapping[selectedOption]}`}
              buttonText={{ primaryButton: "Close" }}
              image={OnBoardComplete}
              secondaryButton={false}
            />
          </Modalui>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.onboardUserButton}>
      <Button
        variant="contained"
        onClick={handleMenuClick}
        startIcon={<PersonIcon />}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
      >
        Onboard User
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#ffffff",
            width: 163,
            color: "#62201c"
          },
          "& .MuiMenuItem-root": {
            "&:hover": {
              border: "1px solid #62201c40",
              background: "#62201c1f"
            }
          }
        }}
      >
        <MenuItem onClick={() => handleModalOpen("AddUser", PARTICIPANT)}>Add Participant</MenuItem>
        <MenuItem onClick={() => handleModalOpen("AddUser", SUPPORTER)}>Add Supporter</MenuItem>
        <MenuItem onClick={() => handleModalOpen("AddUser", FINANCE_MANAGER)}>Add Guardian</MenuItem>
      </Menu>

      {renderModal()}
    </div>
  );
};

export default QuickLinks;
