import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  SubsListRequest,
  SubsRequest,
  createSubscription,
  getSubscription,
  listSubscription,
  saveSubsRequest,
  saveSubscriptionPreference,
  setStandardSubscription,
  toggleSubscription,
  updateSubscription
} from "src/network/graphql/subscriptionServices";
import { immediateToast } from "izitoast-react";
import { subscriptionCategory, subscriptionStandard } from "src/types/commonTypes";

export type SubsList = {
  subsId: string;
  title: string;
  subTitle: string;
  description: string;
  date: string;
  orgType: string;
  status: string;
  standard: subscriptionStandard;
  subscription_category: subscriptionCategory;
  level: number;
  permission: { [role: string]: string[] };
  menu: { [role: string]: string[] };
};

export const initialSubsList: SubsList[] = [];

export const initialSubsDetail: SubsList = {
  subsId: "",
  title: "",
  subTitle: "",
  description: "",
  date: "",
  orgType: "",
  status: "",
  standard: "N",
  subscription_category: "BASE",
  level: 0,
  permission: {},
  menu: {}
};

const plansSlice = createSlice({
  name: "files",
  initialState: {
    loading: false,
    createSubLoading: false,
    createSubPreferenceLoading: false,
    subsPreferenceLoading: false,
    recentCreatedPlanSubId: "",
    error: null,
    subscriptionList: {
      list: initialSubsList,
      size: 0
    },
    subsPreference: initialSubsDetail
  },
  reducers: {
    fetchListSubscriptionStart: (state) => {
      state.loading = true;
    },
    fetchListSubscriptionSuccess: (state, action) => {
      state.loading = false;
      state.subscriptionList = action.payload;
    },
    fetchListSubscriptionFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createSubscriptionStart: (state) => {
      state.createSubLoading = true;
    },
    createSubscriptionSuccess: (state) => {
      state.createSubLoading = false;
    },
    createSubscriptionFail: (state, action) => {
      state.createSubLoading = false;
      state.error = action.payload;
    },
    updateSubscriptionStart: (state) => {
      state.createSubLoading = true;
    },
    updateSubscriptionSuccess: (state) => {
      state.createSubLoading = false;
    },
    updateSubscriptionFail: (state, action) => {
      state.createSubLoading = false;
      state.error = action.payload;
    },
    setRecentCreatedPlanSubId: (state, action) => {
      state.recentCreatedPlanSubId = action.payload;
    },
    saveSubsPreferencesStart: (state) => {
      state.createSubPreferenceLoading = true;
    },
    saveSubsPreferencesSuccess: (state) => {
      state.createSubPreferenceLoading = false;
    },
    saveSubsPreferencesFail: (state, action) => {
      state.createSubPreferenceLoading = false;
      state.error = action.payload;
    },
    setSubsPreferenceLoading: (state, action) => {
      state.subsPreferenceLoading = action.payload;
    },
    fetchSubsPreference: (state, action) => {
      state.subsPreference = action.payload;
    }
  }
});

const {
  fetchListSubscriptionStart,
  fetchListSubscriptionSuccess,
  fetchListSubscriptionFail,
  createSubscriptionStart,
  createSubscriptionSuccess,
  createSubscriptionFail,
  updateSubscriptionStart,
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  setRecentCreatedPlanSubId,
  saveSubsPreferencesStart,
  saveSubsPreferencesSuccess,
  saveSubsPreferencesFail,
  setSubsPreferenceLoading,
  fetchSubsPreference
} = plansSlice.actions;

export const fetchListSubscription = (data: SubsListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchListSubscriptionStart());
    try {
      const response = await listSubscription(data);
      const structuredResponse = response.data.search_subscriptions.items?.map((data: any) => ({
        subsId: data.subscription_id,
        title: data.title,
        subTitle: data.subtitle,
        description: data.description,
        date: data.created_at,
        orgType: data.organisation_type,
        status: data.is_active,
        standard: data.standard,
        subscription_category: data.subscription_category,
        level: data.level
      }));

      dispatch(
        fetchListSubscriptionSuccess({
          list: structuredResponse,
          size: response.data.search_subscriptions.total_size
        })
      );
    } catch (error) {
      dispatch(fetchListSubscriptionFail(error));
    }
  };
};

export const createSubscriptionAction = (data: SubsRequest, standardSubscription: boolean = false, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(createSubscriptionStart());
    try {
      const response = await createSubscription(data);
      const { subscription_id } = response.data.create_subscription;
      dispatch(setRecentCreatedPlanSubId(subscription_id));
      if (standardSubscription) await setStandardSubscription(subscription_id);
      immediateToast("success", {
        message: "Subscription Plan Successfully Created.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(createSubscriptionSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(createSubscriptionFail(error));
    }
  };
};

export const updateSubscriptionAction = (data: SubsRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateSubscriptionStart());
    try {
      await updateSubscription(data);
      immediateToast("success", {
        message: "Subscription Plan Successfully Updated.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(updateSubscriptionSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(updateSubscriptionFail(error));
    }
  };
};

export const toggleSubscriptionStatusAction = (subId: string, isActive: boolean, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateSubscriptionStart());
    try {
      await toggleSubscription(subId, isActive);
      dispatch(updateSubscriptionSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(updateSubscriptionFail(error));
    }
  };
};

export const saveSubscriptionPreferenceAction = (data: saveSubsRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(saveSubsPreferencesStart());
    try {
      await saveSubscriptionPreference(data);
      immediateToast("success", {
        message: "Subscription preferences saved successfully.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(saveSubsPreferencesSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(saveSubsPreferencesFail(error));
    }
  };
};

export const fetchSubsPreferenceAction = (id: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(setSubsPreferenceLoading(true));
    try {
      const response = await getSubscription(id);
      const structuredResponse = response.data.get_subscription;

      dispatch(
        fetchSubsPreference({
          subsId: structuredResponse.subscription_id,
          title: structuredResponse.title,
          subTitle: structuredResponse.subtitle,
          description: structuredResponse.description,
          date: structuredResponse.created_at,
          orgType: structuredResponse.organisation_type,
          status: structuredResponse.is_active,
          permission: structuredResponse.preferences?.permission?.role_permission_mapping,
          menu: structuredResponse.preferences?.menu?.role_menu_mapping
        })
      );

      dispatch(setSubsPreferenceLoading(false));
      if (cb) cb();
    } catch (error) {
      dispatch(setSubsPreferenceLoading(false));
      console.error(error);
    }
  };
};

export const setSubscriptionStandard = (id: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateSubscriptionStart());
    try {
      await setStandardSubscription(id);
      immediateToast("success", {
        message: "Subscription set as standard successfully.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(updateSubscriptionSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(updateSubscriptionFail(error));
    }
  };
};

export default plansSlice.reducer;
